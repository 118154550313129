import React from 'react';
import Home from './Home';

class GlobalComponent extends React.Component {
  
  constructor() {
    super();
    this.state = { elemToShow: <Home setElemToShow={this.setElemToShow} />}
  }

  setElemToShow = (elem) => {
    this.setState({ elemToShow: elem });
  }

  render() {
    return this.state.elemToShow;
  }
}

export default GlobalComponent;
