import React from 'react';
import '../styles/Result.css';
import girl from "../images/girl.png"
import fireworks from "../images/fireworks.gif";
import sunglasses from "../images/sunglasses.png";

class Result extends React.Component {

  render() {
    return (
      <div className="container">
        <img alt="Feu d'artifice" src={fireworks} className="fireworks-image" />
        <div className="manon-image-container">
          <img alt="Lunettes noires" src={sunglasses} className="sunglasses-image slide-sunglasses-animation" width="180px" />
          <img alt="Manon" src={girl} className="manon-image" width="95%" />
        </div>
        <p className="result-headline">Manon est géniale</p>
        <p className="result-headline">❤️</p>
      </div>
    )
  }
}

export default Result;