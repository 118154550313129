import React from 'react';
import '../styles/Home.css';
import girl from "../images/girl.png"
import Analysis from './Analysis';

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { clicked : false };
  }

  render() {
    return (
        <div className={`container ${this.state.clicked ? "fade-out-animation" : ""}`} id="first-container">
          <img alt="Manon" src={girl} className="manon-image" width="150px" />
          <p className="headline" id="headline">Manon est-elle géniale ?</p>
          <button className="main-button" id="main-button" onClick={this.goToAnalysis} disabled={this.state.clicked}><span>Lancer l'analyse</span></button>
        </div>
    );
  }

  goToAnalysis = () => {
    this.setState({ clicked : true });
    setTimeout(() => this.props.setElemToShow(<Analysis setElemToShow={this.props.setElemToShow} />), 1300);
  }

}

export default Home;
