import "../styles/animation.css";
import "../styles/Analysis.css";
import girl from "../images/girl.png";
import file from "../images/file.png";
import computer from "../images/computer.png";
import React, { useEffect, useState } from "react";
import Result from "./Result";

const Analysis = ({setElemToShow}) => {

  const [animationStarted, setAnimationStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimationStarted(true);
      loadResults(0);
    }, 2200);
    //eslint-disable-next-line
  }, []);

  const analyseTexts = [
    "Initialisation",
    "Lancement de l'analyse",
    "Calcul de la coolitude &#128526;",
    "Test du chocolat chaud &#9749;",
    "Contemplation de la beauté &#127801;",
    "Détermination des capacités intellectuelles &#129504;",
    "Calcul d'affinités avec les ours &#128059;",
    "Analyse du sens de l'humour &#128516;",
    "Évaluation du potentiel de génialitude",
    "Validation des résultats",
    "Synthèse des résultats"
  ];

  const loadResults = (iterationNumber) => {
    const analyseTextsLength = analyseTexts.length;
    const resultsTableContainer = document.getElementById("results-table-container");
    const resultsTableRows = document.getElementById("results-table").rows;
    const resultsTableRowsLength = resultsTableRows.length;

    if (iterationNumber < analyseTextsLength) {
      if (iterationNumber > 2 && iterationNumber - 3 < resultsTableRowsLength) {
        resultsTableRows[iterationNumber - 3].style.display = "table-row";
        resultsTableContainer.style.borderTop = "2px solid white";
      }
      const subtext = document.getElementById("subtext");
      subtext.innerHTML = analyseTexts[iterationNumber];
      setTimeout(() => loadResults(iterationNumber + 1), 2500);
    }
    else showResults();
  }

  const showResults = () => {
    setIsLoading(false);
    setTimeout(() => setElemToShow(<Result />), 1700);
  }

    return (
    <div className={`container slide-in-animation ${isLoading ? "" : "analysis-fade-out-animation"}`}>
      <p className="headline">{animationStarted ? "Analyse en cours" : ""}</p>
      <div className="image-container">
        <img alt="Manon" src={girl} className={`${animationStarted ? "ld ld-skew" : ""} girl-image`} style={{animationDuration: '1.5s'}} />
        <img alt="Fichier" src={file} className={`${animationStarted ? "ld ld-slide-ltr" : ""} file-image`} />
        <img alt="Fichier" src={file} className={`${animationStarted ? "ld ld-slide-ltr" : ""} file-image`} style={{animationDelay: '-.07s'}} />
        <img alt="Fichier" src={file} className={`${animationStarted ? "ld ld-slide-ltr" : ""} file-image`} style={{animationDelay: '-.21s'}} />
        <img alt="Ordinateur" src={computer} className={`${animationStarted ? "ld ld-tick" : ""} computer-image`} style={{animationDuration: '1.5s'}} />
      </div>
      <p className="subtext" id="subtext"></p>
      <div className="results-table-container" id="results-table-container">
        <table className="results-table" id="results-table">
          <tbody>
            <tr>
              <td><p className="result-text">Méga cool <span class="result-icon">&#128526;</span></p></td>
            </tr>
            <tr>
              <td><p className="result-text">Amatrice de chocolats chauds <span class="result-icon">&#9749;</span></p></td>
            </tr>
            <tr>
              <td><p className="result-text">La plus belle de toutes <span class="result-icon">&#127801;</span></p></td>
            </tr>
            <tr>
              <td><p className="result-text">Sait se servir de sa tête <span class="result-icon">&#129504;</span></p></td>
            </tr>
            <tr>
              <td><p className="result-text">Grande amie des ours <span class="result-icon">&#128059;</span></p></td>
            </tr>
            <tr>
              <td><p className="result-text">Aussi drôle qu'un meilleur ami <span class="result-icon">&#128516;</span></p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Analysis;